import React from "react";
import { Toolbar, TextField, CircularProgress, Dialog, DialogContent, DialogActions, Button, Box, IconButton, Tooltip } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { Close } from '@material-ui/icons';
import Autocomplete from "@material-ui/lab/Autocomplete";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import axios from "axios";
import { v4 as uuid } from "uuid";
import dictionary from "../../constants/MappingCombination";
import RTLD1dictionary from "../../constants/RTLDSchema1Combination";
import HeaderKeys from "../../constants/headerKey.json";
import HeaderValues from "../../constants/headerValue.json";
import convert from "xml-js";
import config from '../../config/config';
import debounce from 'lodash.debounce';
const style = {color: "grey", fontSize: "1.5em", marginTop: "-7px"};
let newValue, payloadmapping = [], finalValidation = true, flattenObj = {};
const unique_id = uuid();
const NODEURL = config.getConfig().lcmService;
const FULLFILLMENTSERVICE = config.getConfig().fullfillmentService;
class leadConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
          managementProgress: false,
          snackAlertStatus: false,
          fullfillmentProgress: false,
          SpinnerFlag: false,
          open: false,
          validationCheck: false,
          fullfillmentCheck: false,
          ffidNumberCheck: false,
          configExistCheck: false,
          popUp: "",
          message: "",
          allConfigList: [],
          dataSourceList: [],
          dataMangmtList: [],
          Headers: {'Content-Type': ['application/json']},
          Query: [{"queryparamkey": "", "queryparamvalue": ""}],
          XMLPayload: "<?xml version='1.0' encoding='utf-8'?>",
          HeaderDetails: [{"headerkey": "Content-Type", "valuetype": "Value", "headervalue": ["application/json"]}],
          LeadConfig: {
            config_id: props.config_id !== undefined ? props.config_id : unique_id,
            fullfillment_id: "",
            source: "",
            datasource: "",
            management: "",
            apiurl: "",
            apimethod: "POST",
            headers: {},
            queryparams: {},
            payloadschematype: "JSON",
            payload: { key: "value" },
            headerdetails: [{}],
            oauthbody: { key: "value" },
            oauthurl: "",
            password: "",
            username: "",
            payloadmapping: [],
            rtldmapping: [],
      }
    };
    this.onChangeDebounced = debounce(this.onChangeDebounced, 2000)
    newValue = this.state.LeadConfig;
    this.MappedLeadConfig = this.MappedLeadConfig.bind(this);
    this.MappedRTLD1Config = this.MappedRTLD1Config.bind(this);
  }
  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ snackAlertStatus: false });
  setAuthentication = (e) => {
    newValue.authentication = e.target.checked;
    this.setState({
      LeadConfig: newValue,
      authenticationCheck: e.target.checked === true ? true : false,
    });
  };
  randomIndexFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  setValueEncrypt(encryptedPasswordValue) {
    var asciiArr = [];
    var atozArr = [];
    var encryptedString = [];
    var encryptedDBPasswordValue = "";
    if (encryptedPasswordValue.length !== 0) {
      for (var i = 0; i < encryptedPasswordValue.length; i++) {
        asciiArr[i] = encryptedPasswordValue[i].charCodeAt(0);
      }
      for (var chari = 0, code = 65; chari < 26; chari++, code++) {
        atozArr[chari] = String.fromCharCode(code);
      }
      var position = this.randomIndexFromInterval(0, atozArr.length - 1);
      var positionAscii = atozArr[position].charCodeAt(0);
      for (var passi = 0; passi < encryptedPasswordValue.length; passi++) {
        encryptedString[passi] =
          parseInt(asciiArr[passi]) + parseInt(atozArr[position].charCodeAt(0));
      }
      encryptedString[asciiArr.length] = positionAscii;
      for (var eindex = 0; eindex < encryptedString.length; eindex++) {
        encryptedDBPasswordValue = encryptedDBPasswordValue + String.fromCharCode(encryptedString[eindex]);
      }
      return encryptedDBPasswordValue;
    }
  }
  isNumber(str) {
    if (str.trim() === '') {
      return false;
    }
    return !isNaN(str);
  }
  setfullfillment_id = (e) => {
    newValue.fullfillment_id = e.target.value;
    this.setState({ LeadConfig: newValue });
   
    this.onChangeDebounced(e)
  };
  onChangeDebounced = (e) => {
    // Delayed logic goes here
    if(this.isNumber(e.target.value) && e.target.value.length > 0){
      this.customerIdentity(e.target.value);
    }
     this.setState({ffidNumberCheck : this.isNumber(e.target.value) ? false : true });

    if(e.target.value.length === 0){
      newValue.source = "";
      newValue.datasource = "";
      newValue.management = "";
    }
  }

  clearFullfillment = (e) => { 
    newValue.fullfillment_id = "";
    this.setState({
      fullfillmentCheck: false,
      LeadConfig: newValue
     });
  }
  setSource = (e) => {
    newValue.source = e.target.value;
    newValue.fullfillment_id = "";
    newValue.datasource = "";
    newValue.management = "";
    this.setState({ LeadConfig: newValue });
    if (e.target.value === "Unit Rental") {
      this.getDataSourceName("mlr");
    } else if (e.target.value === "New Homes") {
      this.getDataSourceName("new_homes");
    } else if (e.target.value === "MLS") {
    } else {
      this.getDataSourceName("crd");
    }
  };
  setCommon = (e) => {
    const { value, name } = e.target;
    newValue[name] = value;
    if(newValue.payloadschematype === "JSON"){
      this.setState({ HeaderDetails: [{"headerkey": "Content-Type", "valuetype": "Value","headervalue": ["application/json"]}]})
    }
    if(newValue.payloadschematype === "XML"){
      this.setState({ HeaderDetails: [{"headerkey": "Content-Type", "valuetype": "Value","headervalue": ["application/xml"]}]})
    }
    if(newValue.payloadschematype === "HTML"){
      this.setState({ HeaderDetails: [{"headerkey": "Content-Type", "valuetype": "Value","headervalue": ["text/html"]}]})
    }
    this.setState({ LeadConfig: newValue });
    if (name === "datasource") {
      this.getManagement(value);
    }
  };
  addHeader() {
    if(this.state.HeaderDetails.length !== 0 && Object.keys(this.state.HeaderDetails[this.state.HeaderDetails.length - 1]) === ''){
      alert('Please fill the details');
    }
    else {
      this.setState({HeaderDetails: this.state.HeaderDetails.concat([{"headerkey" : "", "valuetype" : "Value", "headervalue": []}])})
    }
  }
  removeHeader(i) {
    this.state.HeaderDetails.splice(i,1)
    this.state.Headers.splice(i,1)
    this.setState({ HeaderDetails: this.state.HeaderDetails, Headers:  this.state.Headers });
  }
  clone = (obj) => Object.assign({}, obj);
  renameKey = (object, key, newKey) => {
    const clonedObj = this.clone(object);
    const targetKey = clonedObj[key];
    delete clonedObj[key];
    clonedObj[newKey] = targetKey;
    return clonedObj;
  };
  setHeaderKey = (e, val, index) => {
    var newaval = this.state.HeaderDetails;
    newaval[index].headerkey = val;
    this.setState({ HeaderDetails: newaval });
  };
  setHeaderValue = (e, val, index) => {
    var newHeaderValue = this.state.HeaderDetails;
    newHeaderValue[index].headervalue = val;
    this.setState({ HeaderDetails: newHeaderValue });
    this.setheaders();
  };
  setheaders(){
    for(let z= 0 ; z < this.state.HeaderDetails.length ; z++){
        let newHeaderValue = Object.assign({}, this.state.Headers, {[this.state.HeaderDetails[z].headerkey]: this.state.HeaderDetails[z].headervalue,});
        this.setState({ Headers: newHeaderValue });
    }
  }
  addQueryParam = () => {
    if(this.state.Query.length !== 0 && Object.keys(this.state.Query[this.state.Query.length - 1]) === ''){
      alert('Please fill the details');
    }
    else {
      this.setState({Query: this.state.Query.concat([{"queryparamkey" : "", "queryparamvalue" : ""}])})
    }
  }
  removeQueryParam(i){
    this.state.Query.splice(i,1)
    this.setState({Query: this.state.Query});
  }
  setQueryParamKey = (e, i) => {
    var newaval = this.state.Query;
    newaval[i].queryparamkey = e.target.value;
    this.setState({Query: newaval});
  }
  setQueryParamValue = (e,i) => {
    var newaval = this.state.Query;
    newaval[i].queryparamvalue = e.target.value;
    this.setState({Query: newaval});
  }
  setAuthType = (e, i) => {
    var newaval = this.state.HeaderDetails;
    newaval[i].valuetype = e.target.value;
    this.setState({HeaderDetails: newaval});
  };
  setBasicAuth = (e) => {
    if (e.target.name === "username") {
      newValue.username = e.target.value;
      this.setState({ LeadConfig: newValue });
    }
    if (e.target.name === "password") {
      newValue.password = e.target.value;
      this.setState({ LeadConfig: newValue });
    }
  };
  setOAuth = (e) => {
    if (e.target.name === "oauthurl") {
      newValue.oauthurl =  e.target.value;
      this.setState({ LeadConfig: newValue });
    }
  };
  setOAuthBody = (e) => {
    if (e.jsObject) {
      newValue.oauthbody =  e.jsObject;
      this.setState({ LeadConfig: newValue });
    }
  };

  setPayload = (e) => {
    if (this.state.LeadConfig.payloadschematype === "JSON") {
      newValue.payload = e.jsObject;
    } else {
    this.setState({ XMLPayload: e.target.value });
    var removeJsonTextAttribute = function(value, parentElement) {
      try {
        const pOpKeys = Object.keys(parentElement._parent);
        const keyNo = pOpKeys.length;
        const keyName = pOpKeys[keyNo - 1];
        const arrOfKey = parentElement._parent[keyName];
        const arrOfKeyLen = arrOfKey.length;
        if (arrOfKeyLen > 0) {
          const arr = arrOfKey;
          const arrIndex = arrOfKey.length - 1;
          arr[arrIndex] = value;
        } else {
          parentElement._parent[keyName] = value;
        }
      } catch (e) {}
    };
    let result1 = convert.xml2json(e.target.value, {
      compact: true,
      spaces: 9,
      nativeType: false,
      ignoreDeclaration: true,
      ignoreInstruction: true,
      ignoreAttributes: true,
      ignoreComment: true,
      ignoreCdata: true,
      ignoreDoctype: true,
      textFn: removeJsonTextAttribute
    });
    const obj = JSON.parse(result1);
    const flatten = (obj, roots=[], sep='.') => Object.keys(obj).reduce((memo, prop) => Object.assign({}, memo, Object.prototype.toString.call(obj[prop]) === '[object Object]' ? flatten(obj[prop], roots.concat([prop]), sep) : {[roots.concat([prop]).join(sep)]: obj[prop]}), {});
    flattenObj = flatten(obj);
    const crushObj = (obj = {}) => Object.keys(obj || {}).reduce((acc, cur) => {
      if (typeof obj[cur] === 'object') {
        acc = { ...acc, ...crushObj(obj[cur])}
      } else { acc[cur] = obj[cur] }
      return acc
    }, {})
    const crushed = crushObj(obj)
    newValue.payload = crushed;
  }
    this.setState({ LeadConfig: newValue });
  };
  async customerIdentity(fulfillmentId) {
    this.setState({fullfillmentProgress : true});
    let API_URL = `${FULLFILLMENTSERVICE}fulfillment_id=${fulfillmentId}&client_id=lcm-ui&metadata=false`;
    const resp = await axios.get(API_URL);
    try {
      if(resp.data.data.division === "Rental"){
        newValue.management = resp.data.data.name;
        if(resp.data.data.rental_management !== undefined){
          newValue.datasource = resp.data.data.rental_management[0].data_source_abbreviation;
          if(resp.data.data.rental_communities !== undefined){
            if(resp.data.data.rental_communities[0].account_type === "Rental Community" ){
              newValue.source = "Community Rental";
            }
            else if(resp.data.data.rental_communities[0].account_type === "Rental Unit") {
              newValue.source = "Unit Rental";
            }
          }
          this.setState({LeadConfig : newValue, fullfillmentProgress : false});
        } 
      }
      else if(resp.data.data.division === "New Homes"){
        newValue.management = resp.data.data.name;
        newValue.source = resp.data.data.division;
        if(resp.data.data.corporation !== undefined){
          newValue.datasource = resp.data.data.corporation[0].data_source_abbreviation;
        } else {
          this.setState({popUp: resp.data.data.message, fullfillmentCheck: true});
        }
        this.setState({LeadConfig : newValue, fullfillmentProgress : false});
      }
      else {
        console.log("response",  resp);
        newValue.fullfillment_id = "";
        newValue.source = "";
        newValue.datasource = "";
        newValue.management = "";
        this.setState({popUp: "Given fullfillment id not found under New Homes/Rental", LeadConfig : newValue, fullfillmentProgress : false, fullfillmentCheck: true});
      }
    } catch (e) {
      newValue.fullfillment_id = "";
      newValue.source = "";
      newValue.datasource = "";
      newValue.management = "";
      this.setState({popUp: e, LeadConfig : newValue, fullfillmentProgress: false, fullfillmentCheck: true});
      console.log(e);
    }
  }
  getDataSourceName(sourceName) {
    console.log("sourceName", sourceName);
    this.setState({ dataSourceProgress: true });
    fetch(`${NODEURL}/getDatasource?source=${sourceName}`)
      .then((res) => {
        console.log('res', res);
        if (res.ok) {
          return res.json();
        }
      })
      .then((response) => {
        console.log(response);
        this.setState({
          dataSourceList: response.dataSourceId,
          dataSourceProgress: false,
        });
      }).catch((error) => { 
        this.setState({dataSourceList : [], dataSourceProgress: false});
        console.log(error)});
  }
  getManagement(val) {
    this.setState({ managementProgress: true });
    fetch(`${NODEURL}/getManagement?source=${this.state.LeadConfig.source}&mls_id=${val}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((response) => {
        console.log(response);
        this.setState({
          dataMangmtList: response.result,
          managementProgress: false,
        });
      }).catch((error) => { 
        this.setState({dataMangmtList : [], managementProgress: false});
        console.log(error)});
  }
  clearSet = (e) => { 
    newValue.config_id = uuid();
    newValue.fullfillment_id = "";
    newValue.source = "";
    newValue.datasource = "";
    newValue.management = "";
    newValue.apimethod = "POST";
    newValue.apiurl = "";
    newValue.headers = {};
    newValue.queryparams = {};
    newValue.payloadschematype = "JSON";
    newValue.payload = { key: "value" };
    newValue.headerdetails= [{}];
    newValue.oauthbody= { key: "value" };
    newValue.oauthurl= "";
    newValue.password= "";
    newValue.username= "";
    newValue.payloadmapping= [];
    newValue.rtldmapping= [];
    this.setState({
      open: false,
      LeadConfig: newValue,
      dataSourceList: [],
      dataMangmtList: [],
      Headers: {'Content-Type': ['application/json']},
      Query: [{"queryparamkey": "", "queryparamvalue": ""}],
      XMLPayload: "<?xml version='1.0' encoding='utf-8'?>",
      HeaderDetails: [{"headerkey": "Content-Type", "valuetype": "Value", "headervalue": ["application/json"]}],
});
  }
  async getleadConfigSourceList() {
    let APIurl = `${NODEURL}/getAllConfiguration`;
    let existingArr = [];
    fetch(`${APIurl}`).then((res) => {
      if (res.ok) { return res.json(); } }).then((response) => {
        console.log("getAllConfiguration", response)
        response.LeadConfiguration.map((item) => {
          existingArr.push({
            source: item.source,
            datasource: item.datasource,
            management:  item.management
          })
        })
        this.setState({ allConfigList : existingArr });
      }).catch((error) => {
        existingArr.push({
          source: "",
          datasource: "",
          management:  ""
        })
        this.setState({ allConfigList : existingArr });
        console.log(error)});
    }
  UNSAFE_componentWillMount() {
    this.getleadConfigSourceList();
    if (this.props.actionMode === "EDIT") {
      console.log(this.props.configuredLeadData);
      this.setState({ HeaderDetails: this.props.configuredLeadData.headerdetails,
                      Headers: this.props.configuredLeadData.headers,
                      Query: this.props.configuredLeadData.queryparams});
      Object.assign(this.state.LeadConfig, this.props.configuredLeadData);
      if (this.props.configuredLeadData.payloadschematype === "XML") {
        this.setState({ XMLPayload: this.props.configuredLeadData.payload });
      }
    }
  }
  MappedLeadConfig() {
    const newarr = (flattenObj !== "") ? Object.keys(flattenObj) : "";
    let leadKeys = Object.keys(this.state.LeadConfig.payload).map((v) => v.toLowerCase() ), arr = [], leadindex = [];
    for (let vkey = 0; vkey < leadKeys.length; vkey++) {
      leadindex.push(vkey);
      var lastkey = (newarr !== "" && this.state.LeadConfig.payloadschematype === "XML" ) ? newarr[vkey].substring(newarr[vkey].lastIndexOf("/") + 1, newarr[vkey].length) : "";
      let keysl = leadKeys[vkey];
      for (let j = 0; j < dictionary.length; j++) {
        const possiblelowercase = dictionary[j].possibleKeys.map(element => { return element.toLowerCase();});
        if (possiblelowercase.indexOf(keysl) > -1) {
          arr.push({
            mapping: vkey,
            lcsField: dictionary[j].LCSdata,
            leadField: (newarr !== "" && this.state.LeadConfig.payloadschematype === "XML" ) ? lastkey : Object.keys(this.state.LeadConfig.payload)[vkey],
            defaultValue: "",
          });
          break;
        }
      }
    }
    let indexarray = leadindex.filter((x) => !arr.find((f) => f.mapping === x));
    for ( let k = 0; k < indexarray.length; k++) {
      var lastkeyunmatch = (newarr !== "" && this.state.LeadConfig.payloadschematype === "XML") ? newarr[indexarray[k]].substring(newarr[indexarray[k]].lastIndexOf("/") + 1, newarr[indexarray[k]].length) : "";
        arr.push({
          mapping: indexarray[k],
          leadField: (newarr !== "" && this.state.LeadConfig.payloadschematype === "XML") ? lastkeyunmatch : Object.keys(this.state.LeadConfig.payload)[indexarray[k]],
          lcsField: "",
          defaultValue: Object.values(this.state.LeadConfig.payload)[
            indexarray[k]
          ],
        });
    }
    payloadmapping = arr.sort((a, b) => {
      return a.mapping > b.mapping ? 1 : -1;
    });
    this.MappedRTLD1Config();
  }
  MappedRTLD1Config = () => {
    let RTLD1Mapping = [], venderindex = [], vendorKey = Object.keys(this.state.LeadConfig.payload).map((v) => v.toLowerCase());
    const newarrRTLD1 = flattenObj !== "" ? Object.keys(flattenObj) : "";
    for (let i = 0; i < Object.keys(this.state.LeadConfig.payload).length; i++ ) {
      venderindex.push(i);
      var lastRTLD1key = (newarrRTLD1 !== "" && this.state.LeadConfig.payloadschematype === "XML" ) ? newarrRTLD1[i].substring(newarrRTLD1[i].lastIndexOf("/") + 1, newarrRTLD1[i].length) : "";
      for (let j = 0; j < RTLD1dictionary.length; j++) {
        const possiblelowercase = RTLD1dictionary[j].possibleKeys.map(element => { return element.toLowerCase(); });
        if (possiblelowercase.indexOf(vendorKey[i]) > -1) {
          RTLD1Mapping.push({
            mapping: i,
            leadField: (newarrRTLD1 !== "" && this.state.LeadConfig.payloadschematype === "XML" ) ? lastRTLD1key : Object.keys(this.state.LeadConfig.payload)[i],
            RTLD1Field: RTLD1dictionary[j].RTLD1data,
            defaultValue: "",
          });
          break;
        }
      }
    }
    let venderindexarray = venderindex.filter( (x) => !RTLD1Mapping.find((f) => f.mapping === x));
    for ( let k = 0; k < venderindexarray.length; k++) {
        RTLD1Mapping.push({
          mapping: venderindexarray[k],
          leadField: (newarrRTLD1 !== "" && this.state.LeadConfig.payloadschematype === "XML" ) ? lastRTLD1key : Object.keys(this.state.LeadConfig.payload)[venderindexarray[k]],
          RTLD1Field: "",
          defaultValue: Object.values(this.state.LeadConfig.payload)[venderindexarray[k]],
        });
    }
    RTLD1Mapping = RTLD1Mapping.sort((a, b) => {
      return a.mapping > b.mapping ? 1 : -1;
    });
    var newvalue = this.state.LeadConfig;
    newvalue.rtldmapping = RTLD1Mapping;
    newvalue.payloadmapping = payloadmapping;
    newvalue.headers = this.state.Headers;
    newvalue.queryparams = this.state.Query;
    newvalue.headerdetails = this.state.HeaderDetails;
    if (this.state.LeadConfig.payloadschematype === "JSON") {
      this.setState({ XMLPayload: undefined });
    }
    if (this.state.LeadConfig.payloadschematype === "XML") {
      newValue.payload = this.state.XMLPayload;
    }
    this.setState({ LeadConfig: newValue });
    this.saveConfiguration();
  };
  checkExistConfig() {
    if(this.props.actionMode === "EDIT"){
      finalValidation = true;
      this.setState({configExistCheck : false});
      return finalValidation;
    }else {
        if(this.state.allConfigList !== undefined || this.state.allConfigList !== []){
        for(let existIndex in this.state.allConfigList){
          if(this.state.allConfigList[existIndex].source === this.state.LeadConfig.source &&
            this.state.allConfigList[existIndex].datasource === this.state.LeadConfig.datasource &&
            this.state.allConfigList[existIndex].management === this.state.LeadConfig.management){
              finalValidation = false;
              this.setState({configExistCheck : true});
            }
        }
      } return finalValidation;
    }
  }
  checkValidation(){
    let urlstr = newValue.apiurl, apiurlcheck = true;
    if((urlstr.indexOf("http://") === 0) || (urlstr.indexOf("https://") === 0)){
      apiurlcheck = false;
    }
    if(newValue.source === "" 
    || newValue.datasource === "" || newValue.apimethod === "" 
    || (apiurlcheck) || newValue.apiurl === "" || newValue.payloadschematype === "" 
    || newValue.headerdetails.headerkey === "" || newValue.headerdetails.valuetype === ""){ 
      finalValidation = false;
      this.setState({validationCheck: true})
    } else {
     finalValidation= true;
    }
    return finalValidation;
  }
  saveConfiguration = () => {
    if(this.checkValidation()){
      if(this.checkExistConfig()){
        this.setState({SpinnerFlag: true});
        let APIURL = "", METHOD = "";
        var newValue = this.state.LeadConfig;
        if (this.state.LeadConfig.password !== undefined && this.state.LeadConfig.password !== "") {
          newValue.password = this.setValueEncrypt(this.state.LeadConfig.password);
          this.setState({ LeadConfig: newValue });
        }
        else {
          newValue.password  = "";
          this.setState({ LeadConfig: newValue });
        }
          if (this.props.actionMode === "EDIT") {
            APIURL = `${NODEURL}/updateConfigWithMapping?config_id=` + this.state.LeadConfig.config_id;
            METHOD = "PATCH";
          } else {
            APIURL = `${NODEURL}/createConfiguration`;
            METHOD = "POST";
          }
    fetch(`${APIURL}`, {
      method: METHOD,
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(this.state.LeadConfig),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((response) => {
       console.log(response);
        this.setState({
          message: response.Message,
          SpinnerFlag: false,
          open: true
        });
        if (this.props.actionMode === "EDIT" || this.props.actionMode === "ADD") {
          setTimeout(
            function () {
              this.props.handleLeadPages(true);
            }.bind(this),
            2000
          );
        }
      })
      .catch((error) => {
        this.setState({
          snackAlertStatus: true,
          message: error.code,
          SpinnerFlag: false
        });
        console.log(error);
      });
  }}};
  render() {
    return (
      <React.Fragment>
        <Toolbar className="header">
          {/* <Typography variant="h6" id="tableTile">Configuration</Typography> */}
        </Toolbar>
        <div>
        {this.state.SpinnerFlag ? (
            <div className="form-spinner">
              <CircularProgress className="CircularProgressStyle" />
            </div>
          ) : null}
          <form>
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                  <label className="control-label col-xs-12 col-sm-4 col-lg-4"> Fullfillment Id: </label>
                  <div className="col-xs-12 col-sm-8 col-lg-8">
                    <input
                      type="text"
                      className="form-control"
                      name="fullfillment_id"
                      required
                      style={{backgroundColor: "#eee"}}
                      value={this.state.LeadConfig.fullfillment_id || ''}
                      onChange={this.setfullfillment_id}
                      disabled={this.props.actionMode === "EDIT"}
                    />
                    </div>
                </div>
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                  <label className="control-label col-xs-12 col-sm-4 col-lg-4">*Source:</label>
                  <div className="col-xs-12 col-sm-8 col-lg-8">
                    <select
                      className="form-control"
                      required="required"
                      style={{backgroundColor: "#eee"}}
                      name="source"
                      onChange={this.setSource}
                      value={this.state.LeadConfig.source}
                      disabled={this.props.actionMode === "EDIT" || this.state.LeadConfig.fullfillment_id !== ""}
                    >
                      <option value="">--Select--</option>
                      <option value="Community Rental">Community Rental</option>
                      <option value="Unit Rental">Unit Rental</option>
                      <option value="New Homes">New Homes</option>
                      <option value="MLS">MLS</option>
                    </select>
                  </div>
                </div>
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                    <label className="control-label col-xs-12 col-sm-4 col-lg-4">*Data Source:</label>
                    <div className="col-xs-12 col-sm-8 col-lg-8">
                      {this.state.dataSourceProgress ? (
                        <CircularProgress className="inputSpinner" />
                      ) : null}
                      {this.state.fullfillmentProgress ? (
                        <CircularProgress className="inputSpinner" />
                      ) : null}
                      <select
                        className="form-control"
                        required="required"
                        style={{backgroundColor: "#eee"}}
                        name="datasource"
                        onChange={this.setCommon}
                        value={this.state.LeadConfig.datasource}
                        disabled={this.props.actionMode === "EDIT" || (this.state.LeadConfig.fullfillment_id !== 0 && this.state.LeadConfig.fullfillment_id !== "")}
                      >
                        <option value="">--Select--</option>
                        {this.state.dataSourceList !== undefined &&
                        this.state.dataSourceList.length ? (
                          this.state.dataSourceList.map((id) => (
                            <option key={id.originaldatasourceid} value={id.mlsid_base}>{id.datasourcename} --- {id.mlsid_base}</option>
                          ))
                        ) : (
                          <option value={this.state.LeadConfig.datasource}>{this.state.LeadConfig.datasource}</option>
                        )}{" "}
                      </select>
                    </div>
                </div>
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                    <label className="control-label col-xs-12 col-sm-4 col-lg-4">Management:</label>
                    <div className="col-xs-12 col-sm-8 col-lg-8">
                      {this.state.managementProgress ? (
                        <CircularProgress className="inputSpinner" />
                      ) : null}
                      <select
                        className="form-control"
                        required
                        style={{backgroundColor: "#eee"}}
                        name="management"
                        onChange={this.setCommon}
                        value={this.state.LeadConfig.management}
                        disabled={this.props.actionMode === "EDIT" || this.state.LeadConfig.fullfillment_id !== "" }
                      >
                        <option value="">--Select--</option>
                        {this.state.dataMangmtList &&
                        this.state.dataMangmtList.length ? (
                          this.state.dataMangmtList.map((id) => (
                            <option key={id.sourcecompanyid} value={`${id.CompanyName}${id.sourcecompanyid}`}> {id.CompanyName ? id.CompanyName : "NA"}</option>
                          ))
                        ) : (
                          <option value={this.state.LeadConfig.management}>{this.state.LeadConfig.management}</option>
                        )}
                      </select>
                    </div>
                </div>
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                    <label className="control-label col-xs-12 col-sm-4 col-lg-4">*API Method:</label>
                    <div className="col-xs-12 col-sm-8 col-lg-8">
                      <select
                        className="form-control"
                        required="required"
                        style={{backgroundColor: "#eee"}}
                        name="apimethod"
                        value={this.state.LeadConfig.apimethod}
                        onChange={this.setCommon}
                      >
                         <option value="">Select</option>
                        <option value="POST">POST</option>
                        <option value="GET">GET</option>
                      </select>
                    </div>
                </div>
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                    <label className="control-label col-xs-12 col-sm-4 col-lg-4">*API Url:
                      <Tooltip title="Starts with http:// or https://" className="ml1">
                        <InfoIcon style={style} /></Tooltip></label>
                    <div className="col-xs-12 col-sm-8 col-lg-8">
                      <input
                        type="text"
                        className="form-control"
                        name="apiurl"
                        required="required"
                        style={{backgroundColor: "#eee"}}
                        value={this.state.LeadConfig.apiurl}
                        onChange={this.setCommon}
                      />
                    </div>
                </div>
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                    <label className="control-label col-xs-12 col-sm-4 col-lg-4">*Payload Type:</label>
                    <div className="col-xs-12 col-sm-8 col-lg-8">
                      <select
                        className="form-control"
                        required
                        style={{backgroundColor: "#eee"}}
                        name="payloadschematype"
                        onChange={this.setCommon}
                        value={this.state.LeadConfig.payloadschematype}
                      >
                        <option value="JSON">JSON</option>
                        <option value="XML">XML</option>
                        <option value="HTML">HTML</option>
                      </select>
                    </div>
                </div>
            <div className="col-xs-12 col-sm-12 col-lg-12">
                <label className="control-label col-xs-12 col-sm-2 col-lg-2">Query Param:</label> 
                <div className="header-group col-xs-12 col-sm-10 col-lg-10" style={{padding: "0", left: "9px"}}>
                {this.state.Query !== undefined
                  ? this.state.Query.map((id, i) => {
                      return (
                        <div className="col-xs-12 col-sm-6 col-lg-6" key={i} style={{padding:"0"}}>
                          <div className="col-xs-12 col-sm-3 col-lg-3" style={{ width: "26%", padding:"0"}}>
                          <TextField 
                            size="small"
                            name="queryparamkey"
                            type="text"
                            label="Key"
                            value={id.queryparamkey}
                            variant="outlined"
                            style={{backgroundColor: "#eee"}}
                            onChange={(e) => this.setQueryParamKey(e, i)}
                          />
                          </div>
                          <div className="col-xs-12 col-sm-3 col-lg-3" style={{ width: "26%", padding:"0"}}>
                          <TextField
                            size="small"
                            name="queryparamvalue"
                            type="text"
                            label="Value"
                            value={id.queryparamvalue}
                            variant="outlined"
                            onChange={(e) => this.setQueryParamValue(e, i)}
                            style={{backgroundColor: "#eee" }}
                          />
                          </div>
                          <div className="col-xs-1 col-sm-1 col-lg-1 text-left" style={{bottom: "12px",left:"-5px", padding: "0", width:"1px", textAlign: "center", alignItems: "center"}}> 
                            <a className="btn btn-sm" style={{width: "50px", color: "blue"}} onClick={() => this.addQueryParam()}>+</a> 
                            <a className="btn btn-sm" style={{width: "50px", color: "blue"}} onClick={() => this.removeQueryParam(i)}>-</a>
                            </div> 
                        </div>
                      );
                    })
                  : null}
                {/* <div className="form-group col-xs-6 col-sm-6 col-lg-6 text-right">
                  <button type="button" className="btn btn-sm btn-link" onClick={() => this.addQueryParam()}>+</button>
                </div> */}
             </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12">
                <label className="control-label col-xs-12 col-sm-2 col-lg-2">Header: </label> 
                <div className="header-group col-xs-12 col-sm-10 col-lg-10"  style={{left: "-35px"}}>
                {this.state.HeaderDetails !== undefined
                  ? this.state.HeaderDetails.map((key, index) => {
                      return (
                        <div className="col-xs-6 col-sm-10 col-lg-10" key={index}>
                          <div className="col-xs-12 col-sm-2 col-lg-2">
                            <Autocomplete
                              freeSolo
                              id="size-small-outlined"
                              size="small"
                              disableClearable
                              options={HeaderKeys}
                              getOptionLabel={(option) => option}
                              value={key.headerkey}
                              onInputChange={(e, val) =>
                                this.setHeaderKey(e, val, index)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                  style={{backgroundColor: "#eee"}}
                                  size="medium"
                                  label="Key"
                                  placeholder="key"
                                  name="headerkey"
                                />
                              )}
                            />
                          </div>
                          <div className="col-xs-12 col-sm-2 col-lg-2">
                          <select
                              className="form-control"
                              required
                              name="valuetype"
                              value={this.state.HeaderDetails[index].valuetype}
                              onChange={(e) => this.setAuthType(e, index)}
                              style={{backgroundColor: "#eee", height: "37px"}}
                            >
                              <option value="Value">Value</option>
                              <option value="IpAddress">IPAddress</option>
                              <option value="Basic">Basic Auth</option>
                              <option value="OAuth">OAuth</option>
                            </select>
                        </div>
                        {this.state.HeaderDetails[index].valuetype === "Value" || this.state.HeaderDetails[index].valuetype === "IpAddress" ?
                        <div className="col-xs-12 col-sm-4 col-lg-4">
                          <Autocomplete
                            multiple
                            freeSolo
                            id="checkboxes-tags-demo"
                            size="small"
                            options={HeaderValues}
                            value={key.headervalue}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                style={{backgroundColor: "#eee"}}
                                size="small"
                                label="Value"
                                placeholder="value"
                                name="headervalue"
                              />
                            )}
                            onChange={(e, val) =>
                              this.setHeaderValue(e, val, index)
                            }
                          />
                        </div> : null }
                        { this.state.HeaderDetails[index].valuetype === "Basic" ? (
                        <div className="col-xs-12 col-sm-4 col-lg-4">
                          <TextField
                            required
                            size="small"
                            name="username"
                            type="text"
                            label="Username"
                            style={{backgroundColor: "#eee"}}
                            value={this.state.LeadConfig.username}
                            variant="outlined"
                            onChange={this.setBasicAuth}
                          />
                          <TextField
                            required
                            size="small"
                            name="password"
                            type="password"
                            label="Password"
                            style={{backgroundColor: "#eee",left: "20px"}}
                            value={this.state.LeadConfig.password}
                            variant="outlined"
                            onChange={this.setBasicAuth}
                          />
                        </div>
                        ) : this.state.HeaderDetails[index].valuetype === "OAuth"  ? (
                        <div className="col-xs-12 col-sm-4 col-lg-4">
                            <TextField
                              required
                              size="small"
                              style={{width: '100%', backgroundColor:"#eee" }}
                              name="oauthurl"
                              type="text"
                              label="Auth URL"
                              value={this.state.LeadConfig.oauthurl}
                              variant="outlined"
                              onChange={this.setOAuth}
                            />
                            <JSONInput
                              width="100%"
                              colors={{background : "#eee", string: "black",keys:"blue", number: "green"}}
                              style={{container: {borderRadius: "5px"}}}
                              placeholder={this.state.LeadConfig.oauthbody}
                              name="oauthbody"
                              locale={locale}
                              height="1%"
                              onChange={this.setOAuthBody}
                            />
                         </div>
                        ) : null }
                        <div className="col-xs-2 col-sm-2 col-lg-2 text-right" style={{ bottom: "12px",left:"-10px", padding: "0", width:"1px", textAlign: "center", alignContent:"center" }}>
                            <span style={{display: "inline-block", width: "70px"}}> 
                            <a className="btn btn-sm" style={{width: "50px", color: "blue"}} onClick={() => this.addHeader()}>+</a> 
                            <a className="btn btn-sm" style={{width: "50px", color: "blue"}} onClick={() => this.removeHeader(index)}>-</a>
                            </span> 
                          </div>
                        </div>
                      );
                    })
                  : null}
                
                </div>
            </div>
            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                <label className="control-label col-sm-4 col-lg-4">*Customer Payload:</label>
                {this.state.LeadConfig.payloadschematype === "JSON" ? (
                  <div className="col-xs-12 col-sm-8 col-lg-8">
                    <JSONInput
                      width="100%"
                      colors={{background : "#eee", string: "black",keys:"blue", number: "green"}}
                      style={{container: {borderRadius: "5px"}}}
                      placeholder={this.state.LeadConfig.payload}
                      name="payload"
                      locale={locale}
                      height="1%"
                      onChange={this.setPayload}
                    />
                  </div>
                ) : (
                  <div className="col-xs-12 col-sm-8 col-lg-8">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{backgroundColor: "#eee"}}
                      name="xmlPayload"
                      rows="5"
                      defaultValue={this.state.XMLPayload}
                      onChange={this.setPayload}
                    />
                  </div>
                )}
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 text-center">
              <button type="button" className="btn btn-sm fbtn" onClick={this.MappedLeadConfig}>Save </button>
              <button type="button" className="btn btn-sm fbtn" onClick={() => this.props.actionMode === "EDIT" ? this.props.handleLeadPages(true) : this.clearSet()}> Cancel </button>
            </div>
          </form>
        </div>
        <Dialog open={this.state.ffidNumberCheck} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}> <IconButton onClick={() => this.setState({ffidNumberCheck : false})} ><Close /></IconButton> </Box>
            <DialogContent> <h5>Fullfillment id must be number</h5> </DialogContent>
            <DialogActions> <Button color="primary" variant="contained" onClick={() => this.setState({ffidNumberCheck : false})} >Ok</Button></DialogActions>
        </Dialog>
        <Dialog open={this.state.fullfillmentCheck} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}> <IconButton onClick={this.clearFullfillment} >  <Close /> </IconButton> </Box>
            <DialogContent> <h5>{this.state.popUp}</h5>  </DialogContent>
            <DialogActions> <Button color="primary" variant="contained" onClick={this.clearFullfillment} > Ok </Button> </DialogActions>
          </Dialog>
        <Dialog open={this.state.open} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}>
              <IconButton onClick={this.clearSet} >
                <Close />
              </IconButton>
            </Box>
            <DialogContent>
            {this.props.actionMode === "EDIT" ? <h5>Record updated successfully</h5> : <h5>Record saved successfully</h5>}
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={this.clearSet} >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.configExistCheck} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}>
              <IconButton onClick={() => this.setState({configExistCheck : false})} >
                <Close />
              </IconButton>
            </Box>
            <DialogContent>
             <h5>Entered management set already exist in database</h5>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={() => this.setState({configExistCheck : false})} >Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.validationCheck} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}>
              <IconButton onClick={() => this.setState({validationCheck : false})} >
                <Close />
              </IconButton>
            </Box>
            <DialogContent>
             <h5>Please enter mandatory fields!</h5>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="contained" onClick={() => this.setState({validationCheck : false})} >Ok</Button>
            </DialogActions>
          </Dialog>
      </React.Fragment>
    );
  }
}
export default leadConfig;
