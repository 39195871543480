import React from "react";
import { CircularProgress, Dialog, DialogContent, DialogActions, Button, Box, IconButton} from "@material-ui/core";
import { Close } from '@material-ui/icons';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Input from "@mui/material/Input";
import RTLD1Mapper from "./RTLD1Mapper";
import BootstrapInput from "../../components/BootstrapInput";
import config from '../../config/config';
import debounce from 'lodash.debounce';
const NODEURL = config.getConfig().lcmService;
let newValue;
  export default class MappingFilter extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        ConfigData: {},
        tableCheck: false,
        fullfillmentCheck: false,
        tableProgress: false,
        sourceProgress: false,
        dataSourceProgress: false,
        managementProgress: false,
        ffidNumberCheck: false,
        LeadConfig: {
          source: "",
          datasource: "",
          management: "",
          payloadmapping: [],
          rtldmapping: []
        },
      };
      this.onChangeDebounced = debounce(this.onChangeDebounced, 2000)
      newValue = this.state.LeadConfig;
    }
    clearSet = (e) => { 
      newValue.fullfillment_id = "";
      this.setState({
        fullfillmentCheck: false,
        LeadConfig: newValue
       });
    }
    clearFilters = (e) => {
      let ResetVal = this.state.LeadConfig;
      ResetVal.source = "";
      ResetVal.datasource = "";
      ResetVal.management = "";
      ResetVal.fullfillment_id = "";
      this.setState({LeadConfig: ResetVal , tableCheck : false});
    }
    async getfullfillmetDetails(){
      this.setState({sourceProgress: true});
      let APIurl = `${NODEURL}/getConfiguration?fullfillment_id=` + this.state.LeadConfig.fullfillment_id;
      fetch(`${APIurl}`).then((res) => {
        if (res.ok) {
          return res.json();
        }
        }).then((response) => {
          if(response.Count === 0){
            this.setState({fullfillmentCheck : true, sourceProgress: false});
          } else{
            console.log("filterdfullfillmentData",response);
            newValue.source = response.Items[0].source ;
            newValue.datasource = response.Items[0].datasource ;
            newValue.management = response.Items[0].management ;
            this.setState({fullfillmentCheck : false, ConfigData:  response.Items[0], LeadConfig: newValue , sourceProgress: false, tableCheck: true});
          }
        }).catch((error) => {
          newValue.source= "";
          newValue.datasource= "";
          newValue.management= "";
          this.setState({fullfillmentCheck: false, ConfigData: [], LeadConfig: newValue, sourceProgress: false, tableCheck: false});
        });
    }
    async getleadConfigSourceList() {
      this.setState({ sourceProgress: true});
      let APIurl = `${NODEURL}/getAllConfiguration`;
      fetch(`${APIurl}`).then((res) => {
        if (res.ok) { return res.json(); } }).then((response) => {
          console.log("filterdSources", response)

          const unique = [...new Set(response.LeadConfiguration.map((item) => item.source))];
          this.setState({ filterdSources: unique, sourceProgress: false });

        }).catch((error) => { 

          this.setState({filterdSources : [], sourceProgress: false});

          console.log(error)});
    }
    async getleadConfigDataSourceList() {
      this.setState({dataSourceProgress: true});
      let APIurl = `${NODEURL}/getConfiguration?source=` + this.state.LeadConfig.source;

      fetch(`${APIurl}`).then((res) => {
        if (res.ok) { return res.json();} }).then((response) => {
          console.log("filterdDataSources",response)

          const unique = [...new Set(response.Items.map((item) => item.datasource))];
          this.setState({ filterdDataSources: unique, dataSourceProgress: false });

        }).catch((error) => {
          
          this.setState({filterdDataSources: [], dataSourceProgress: false});

          console.log(error)
        });
    }
    async getleadConfigManagementList() {
      this.setState({ managementProgress: true , tableProgress: true}); 
      let APIurl = `${NODEURL}/getConfiguration?source=` + this.state.LeadConfig.source +`&datasource=` + this.state.LeadConfig.datasource;
      fetch(`${APIurl}`).then((res) => {
        if (res.ok) {
          return res.json();
        }
        }).then((response) => {
          console.log("filteredManagement",response);

          const unique = response.Items.filter(item => {return item.management !== "" && item.management !== undefined } );
          const withoutManagement = response.Items.filter(item => {return item.management === ""} );

          this.setState({
            filteredManagement: unique,
            ConfigData: response.Items.length > 0 ? withoutManagement[0] : {},
            managementProgress: false,
            tableCheck: withoutManagement.length === 1 ? true : false,
            tableProgress: false
          })

        }).catch((error) => {
          this.setState({ConfigData: [], managementProgress: false});
          console.log(error);
        });
    }
    async getleadConfigData() {
      this.setState({ tableCheck: false , tableProgress: true});
      let APIurl = `${NODEURL}/getConfiguration?source=` + this.state.LeadConfig.source +`&datasource=` +this.state.LeadConfig.datasource +`&management=` +  this.state.LeadConfig.management;
      fetch(`${APIurl}`).then((res) => {
        if (res.ok) { return res.json();} }).then((response) => {
          console.log("filterdDataDetails",response)

          this.setState({
            ConfigData: response.Items.length > 0 ? response.Items[0] : {},
            SpinnerFlag: false,
            tableCheck: response.Items.length === 1 ? true : false,
            tableProgress: false
          });

        }).catch((error) => {

          this.setState({ConfigData: [], SpinnerFlag: false});
          console.log(error);
        });
    }
    setfullfillment_id = (e) => {
      newValue.fullfillment_id = e.target.value;
      this.setState({ LeadConfig: newValue });
      this.onChangeDebounced(e);
    };
    onChangeDebounced = (e) => {
      // Delayed logic goes here
      if(this.isNumber(e.target.value) && e.target.value.length > 0){
        this.getfullfillmetDetails(e.target.value);
      }
       this.setState({ffidNumberCheck : this.isNumber(e.target.value) ? false : true });
  
      if(e.target.value.length === 0){
        newValue.source = "";
        newValue.datasource = "";
        newValue.management = "";
        this.setState({fullfillmentCheck: false, ConfigData: [], LeadConfig: newValue, sourceProgress: false, tableCheck: false});
      }
    }
    isNumber(str) {
      if (str.trim() === '') {
        return false;
      }
      return !isNaN(str);
    }
    setCommon = (event) => {
      const { value, name } = event.target;
      if (name === "source") {
        newValue[name] = value;
        this.getleadConfigDataSourceList();
      }
      if (name === "datasource") {
        newValue[name] = value;
        this.getleadConfigManagementList();
      }
      if (name === "management") {
        newValue[name] = value;
        this.getleadConfigData();
      }
      this.setState({ LeadConfig: newValue });
    };
    // setServiceType = (e) => {
    //   this.setState({ servicetype: e.target.value });
    // };
    UNSAFE_componentWillMount() {
      this.getleadConfigSourceList();
    }
    
    render() {
      return (
          <React.Fragment>
          <form>
          <div className="form-group col-xs-12 col-sm-12 col-lg-12 filter-bg">
            <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
                <FormControl style={{width: "100%"}}>
                <InputLabel style={{ fontSize: "13px" }}>Fullfillment Id</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="fullfillment_id"
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.fullfillment_id || ''}
                  onChange={this.setfullfillment_id}
                >
                </Input>
              </FormControl>
            </div>
            <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
            { this.state.LeadConfig.fullfillment_id ?
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Source</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="source" 
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.source}
                  disabled
                >
                </Input>{this.state.sourceProgress ? (<CircularProgress className="inputSpinner" /> ) : null}
              </FormControl> : 
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Source</InputLabel>
                <NativeSelect
                  value={this.state.LeadConfig.source}
                  name="source"
                  input={<BootstrapInput />}
                  onChange={this.setCommon}
                >
                  <option aria-label="None" value="" />
                  {this.state.filterdSources !== undefined
                    ? this.state.filterdSources.map((id) => (
                        <option key={id} value={id}>
                          {id}
                        </option>
                      ))
                    : ""}
                </NativeSelect>
                {this.state.sourceProgress ? (<CircularProgress className="inputSpinner" /> ) : null} </FormControl>}
            </div>
            <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
              { this.state.LeadConfig.fullfillment_id ?
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Datasource</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="datasource" 
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.datasource}
                  disabled
                >
                </Input>
              </FormControl> : 
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>*Datasource</InputLabel>
                <NativeSelect
                  value={this.state.LeadConfig.datasource}
                  name="datasource"
                  input={<BootstrapInput />}
                  onChange={this.setCommon}
                >
                  <option aria-label="None" value="" />
                  {this.state.filterdDataSources !== undefined
                    ? this.state.filterdDataSources.map((id) => (
                        <option key={id} value={id}> {id} </option>
                      ))
                    : ""}
                </NativeSelect>
                {this.state.dataSourceProgress ? (<CircularProgress className="inputSpinner" /> ) : null}
              </FormControl> }
            </div>
            <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
            { this.state.LeadConfig.fullfillment_id ?
              <FormControl style={{width: "100%"}}><InputLabel style={{ fontSize: "13px" }}>Management</InputLabel>
                <Input size="md" style={{backgroundColor: "white", marginTop: "10px", borderRadius: "6px"}}
                  name="management" 
                  input={<BootstrapInput />}
                  value={this.state.LeadConfig.management}
                  disabled
                >
                </Input>
              </FormControl> : 
              <FormControl style={{width: "100%"}}> <InputLabel style={{ fontSize: "13px" }}>Management</InputLabel>
                <NativeSelect
                  value={this.state.LeadConfig.management}
                  name="management"
                  input={<BootstrapInput />}
                  onChange={this.setCommon}
                >
                  <option aria-label="None" value="" />
                  {this.state.filteredManagement !== undefined
                    ? this.state.filteredManagement.map((id) => (
                        <option key={id.management} value={id.management}>{id.management}</option>
                      ))
                    : ""}
                </NativeSelect>
                {this.state.managementProgress ? ( <CircularProgress className="inputSpinner" /> ) : null}
              </FormControl> }
            </div>
            <Button color="primary" variant="contained" onClick={this.clearFilters}>Reset</Button>
            {/* <div className="col-xs-12 col-sm-2 col-lg-2 filter-label">
              
              <FormControl style={{width: "100%"}}>
                <InputLabel style={{ fontSize: "13px" }}>*Service Type</InputLabel>
                <NativeSelect
                  value={this.state.servicetype}
                  name="servicetype"
                  input={<BootstrapInput />}
                  onChange={this.setServiceType}
                >
                  <option aria-label="None" value="" />
                  <option value="Partner">Partner Service</option>
                  <option value="RTLD1">RTLD Schema</option>
                </NativeSelect>
              </FormControl>
            </div> */}
           </div> 
           { this.state.tableProgress ? 
              <div className="form-spinner">
              <CircularProgress className="CircularProgressStyle" />
            </div> : null}
        </form>
        <Dialog open={this.state.ffidNumberCheck} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}> <IconButton onClick={() => this.setState({ffidNumberCheck : false})} ><Close /></IconButton> </Box>
            <DialogContent> <h5>Fullfillment id must be number</h5> </DialogContent>
            <DialogActions> <Button color="primary" variant="contained" onClick={() => this.setState({ffidNumberCheck : false})} >Ok</Button></DialogActions>
        </Dialog>
        <Dialog open={this.state.fullfillmentCheck} maxWidth="xs" fullWidth>
            <Box position="absolute" top={0} right={0}> <IconButton onClick={this.clearSet} >  <Close /> </IconButton> </Box>
            <DialogContent> <h5>No matched record found in database</h5>  </DialogContent>
            <DialogActions> <Button color="primary" variant="contained" onClick={this.clearSet} > Ok </Button> </DialogActions>
        </Dialog>
          
           <div className="tableWrapper">
            {/* {this.state.servicetype === "Partner" && this.state.tableCheck ? 
            <Mapper ScannedData={this.state.ConfigData.payloadmapping} ConfigId={this.state.ConfigData.config_id} ServiceType={this.state.servicetype}/> : null} */}
             { this.state.tableCheck ? 
             <RTLD1Mapper ScannedData={this.state.ConfigData.rtldmapping} ConfigId={this.state.ConfigData.config_id}/> : null}
          </div> 
      </React.Fragment>
      );
    }
  }
  