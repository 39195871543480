import React, { useEffect, useState, Fragment } from "react";
import ReadOnlyRow from "./RTLD1MapperComponents/ReadOnlyRow";
import EditableRow from "./RTLD1MapperComponents/EditableRow";
import { Close } from '@material-ui/icons';
import { Dialog, DialogContent, DialogActions, Button, Box, IconButton } from "@material-ui/core";
import config from '../../config/config';
const NODEURL = config.getConfig().lcmService;
const RTLD1Mapper = (props) => {
  const [RTLD1mappingData, setRTLD1MappingData] = useState([]);
  const [open, setSnackBar] = useState(false);
  const [message, setMessage] = useState();
  
  const fetchRTLD1Mapping = () => {
    setRTLD1MappingData(props.ScannedData);
  };

  useEffect(() => {
    fetchRTLD1Mapping();
  }, []);

  const [addFormData, setAddFormData] = useState({
    leadField: "",
    RTLD1Field: "",
    mapping: "",
    defaultValue: "",
  });

  const [editFormData, setEditFormData] = useState({
    leadField: "",
    RTLD1Field: "",
    mapping: "",
    defaultValue: "",
  });

  const [editContactId, setEditContactId] = useState(null);

  const handleAddFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;

    setAddFormData(newFormData);
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();
    const newContact = {
      mapping: RTLD1mappingData.length,
      leadField: addFormData.leadField,
      RTLD1Field: addFormData.RTLD1Field,
      defaultValue: editFormData.defaultValue,
    };
    const newMapping = [...RTLD1mappingData, newContact];
    setRTLD1MappingData(newMapping);
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();

    const editedContact = {
      mapping: editContactId,
      leadField: editFormData.leadField,
      RTLD1Field: editFormData.RTLD1Field,
      defaultValue: editFormData.defaultValue,
    };

    const newMapping = [...RTLD1mappingData];

    const index = RTLD1mappingData.findIndex(
      (mData) => mData.mapping === editContactId
    );

    newMapping[index] = editedContact;
    setRTLD1MappingData(newMapping);
    setEditContactId(null);
  };

  const handleEditClick = (event, mData) => {
    event.preventDefault();
    setEditContactId(mData.mapping);

    const formValues = {
      leadField: mData.leadField,
      RTLD1Field: mData.RTLD1Field,
      defaultValue: mData.defaultValue,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    setEditContactId(null);
  };

  const handleDeleteClick = (mappingIndex) => {
    const newMapping = [...RTLD1mappingData];

    const index = RTLD1mappingData.findIndex(
      (mData) => mData.mapping === mappingIndex
    );

    newMapping.splice(index, 1);
    setRTLD1MappingData(newMapping);
  };

  const handleClose = () => {setSnackBar(false); setRTLD1MappingData([]);}
  const updateMapping = (id, newRTLD1Mapping) => {
    fetch(`${NODEURL}/updateMapping?config_id=` + id, {
      method: "PATCH",
      body: JSON.stringify(newRTLD1Mapping),
      headers: {"Content-type": "application/json"},
    })
      .then((response) => response.json())
      .then((json) => { setSnackBar(true);
        setMessage("Record updated successfully");
      }).catch((error) => { setSnackBar(true);
        setMessage(error);
      });
  };
  const onSave = (id) => {
    const newMapping = RTLD1mappingData;
    updateMapping(id, newMapping);
  };
  return (
    <div className="wrap">
      <form onSubmit={handleEditFormSubmit}>
      <h5 style={{color: "blue"}}>{editContactId !== undefined ? `Mapping (${RTLD1mappingData.length} fields)` : "" }</h5>
        <table>
          <thead>
            <tr>
              <th>LeadField</th>
              <th>RTLDField</th>
              <th>DefaultValue</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {RTLD1mappingData.map((mData) => (
              <Fragment>
                {editContactId === mData.mapping ? (
                  <EditableRow
                    editFormData={editFormData}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                  />
                ) : (
                  <ReadOnlyRow
                    mData={mData}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </form>

      <h5>Add Custom Field</h5>
      <form onSubmit={handleAddFormSubmit} style={{display: "flex", gap: "5px"}}>
        <input
          type="text"
          name="leadField"
          required="required"
          placeholder="Enter a leadField..."
          onChange={handleAddFormChange}
        />
        <input
          type="text"
          name="RTLD1Field"
          placeholder="Enter a RTLDField..."
          onChange={handleAddFormChange}
        />
        <input
          type="text"
          name="defaultValue"
          placeholder="Enter a DefaultValue..."
          onChange={handleAddFormChange}
        />
        <button type="submit">Add</button>
      </form>
      <div className="col-xs-12 col-sm-12 col-lg-12 text-center"> 
      <button type="button" className="btn btn-sm fbtn" onClick={() => onSave(props.ConfigId)}>
        {" "}
        Save{" "}
      </button>
      </div>
      <Dialog open={open} maxWidth="xs" fullWidth>
          <Box position="absolute" top={0} right={0}>
              <IconButton onClick={handleClose} >
                <Close />
              </IconButton>
          </Box>
          <DialogContent>
             <h5>{message}</h5>
          </DialogContent>
          <DialogActions>
              <Button color="primary" variant="contained" onClick={handleClose} >Ok</Button>
          </DialogActions>
        </Dialog>
    </div>
  );

};

export default RTLD1Mapper;
