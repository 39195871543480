import React from "react";
import LeadConfiguration from "./LeadConfiguration";
import LeadDashboardList from "./LeadDashboardList";
import { v4 as uuid } from "uuid";

class LeadDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LeadDashboardPageStatus: true,
      ActionMode: true,
      ConfiguredLeadData: {},
    };
  }
  handleLeadPages = (val, mode, data) => {
    const unique_id = uuid();
    this.setState({
      LeadDashboardPageStatus: val,
      ActionMode: mode,
      ConfigId: unique_id,
      ConfiguredLeadData: data,
    });
  };
  render() {
    let flag = { view: true, create: true, edit: true };
    return (
      <div>
        {this.state.LeadDashboardPageStatus ? (
          <LeadDashboardList
            handleLeadPages={this.handleLeadPages}
            createFlag={flag.create}
            editFlag={this.edit}
          />
        ) : (
          <LeadConfiguration
            handleLeadPages={this.handleLeadPages}
            actionMode={this.state.ActionMode}
            config_id={this.state.ConfigId}
            configuredLeadData={this.state.ConfiguredLeadData}
          />
        )}{" "}
      </div>
    );
  }
}
export default LeadDashboard;
